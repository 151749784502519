import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { Row, Shim, PageTitle } from "../components/row"
import styled, { css } from "styled-components"
import CSS from "../config/css"
import SEO from "../components/seo"

import placeholderImg from "../images/menu/menuitem-placeholder.gif"

const pageTitle = "Weekly Menu"

const CategoryTitle = styled.h2`
  margin-top: ${CSS.gutter};
`

const CategoryDescription = styled.div`
  margin-bottom: ${CSS.gutter};
  padding: ${CSS.margin};
  background-color: #f0f0f0;
  &:empty {
    margin: 0;
    padding: 0;
    background-color: none;
  }
`

const MenuSet = styled.div`
  display: flex;
  flex-direction: column;
  @media ${CSS.mq.medium} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const MenuItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${CSS.margin};
  @media ${CSS.mq.medium} {
    width: 50%;
  }
`

const MenuItems = props => {
  return props.items.map(item => (
    <MenuItem key={item.node.id} item={item.node.data} />
  ))
}

const MenuItem = props => {
  const formattedPrice =
    (props.item.Price && `$${props.item.Price.toFixed(2)}`) || ""
  if (props.item.Image !== null && props.item.Image.localFiles !== null) {
    return (
      <MenuItemWrapper>
        <div style={{ flex: "1 1 100px", marginRight: "16px" }}>
          <Img
            fixed={props.item.Image.localFiles[0].childImageSharp.fixed || ""}
            alt={props.item.Name || ""}
          />
        </div>
        <div style={{ flex: "1 1 calc(100% - 116px)" }}>
          <strong>{props.item.Name}</strong>{" "}
          <span style={{ backgroundColor: `${CSS.colors.lightmint}` }}>
            {formattedPrice}
          </span>
          <p style={{ fontSize: "80%", marginTop: "0" }}>
            {props.item.Description}
          </p>
        </div>
      </MenuItemWrapper>
    )
  } else {
    return (
      <MenuItemWrapper>
        <div style={{ flex: "1 1 130px", marginRight: "16px" }}>
          <img
            src={placeholderImg}
            alt={props.item.Name || ""}
            style={{ minWidth: "130px" }}
          />
        </div>
        <div style={{ flex: "1 1 calc(100% - 116px)" }}>
          <strong>{props.item.Name}</strong>{" "}
          <span style={{ backgroundColor: `${CSS.colors.lightmint}` }}>
            {formattedPrice}
          </span>
          <p style={{ fontSize: "80%", marginTop: "0" }}>
            {props.item.Description}
          </p>
        </div>
      </MenuItemWrapper>
    )
  }
}

class Menu extends React.Component {
  render() {
    let orderedItems = []

    // Make sure there are items in this menu
    if (this.props.data.allAirtable !== null) {
      const menuItems = this.props.data.allAirtable.group

      // Order the categories correctly
      menuItems.forEach(group => {
        orderedItems[group.edges[0].node.fields.Category_Order] = {
          category: group.edges[0].node.fields.Category,
          categoryDescription: group.edges[0].node.fields.Category_Description,
          items: group.edges,
        }
      })
    }

    return (
      <Layout>
        <SEO title={pageTitle} />
        <Row>
          <PageTitle>{pageTitle}</PageTitle>
          <p style={{ textAlign: `center` }}>
            <strong>We update our weekly in-store menu every Tuesday.</strong> If you have any
            questions about any of our current flavors please give us a call.
          </p>
          {orderedItems.map(group => {
            const description = group.categoryDescription
              ? group.categoryDescription
              : ""
            if (group.items.length > 0) {
              return (
                <div key={group.category}>
                  <CategoryTitle>{group.category}</CategoryTitle>
                  <CategoryDescription>{description}</CategoryDescription>
                  <MenuSet>
                    <MenuItems items={group.items} />
                  </MenuSet>
                </div>
              )
            }
          })}
        </Row>
      </Layout>
    )
  }
}

export default Menu

export const menuQuery = graphql`
  query groupedItems($week: [Int]) {
    allAirtable(
      filter: { table: { eq: "Items" }, fields: { Week_Number: { in: $week } } }
      sort: { fields: [data___Name], order: ASC }
    ) {
      group(field: fields___Category) {
        edges {
          node {
            id
            fields {
              Week_Number
              Category
              Category_Order
              Category_Description
            }
            data {
              Name
              Parent
              Description
              Price
              Order
              Image {
                id
                localFiles {
                  childImageSharp {
                    fixed(width: 130, height: 130) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              Category {
                id
                data {
                  Name
                  Description
                }
              }
            }
          }
        }
      }
    }
  }
`
